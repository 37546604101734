import {RepositoryFactory as Repo} from "@/repository/RepositoryFactory"
import router from '../../router'

const PR = Repo.get('request')

const state = {
   user: {},
   admin: {},
   token: null,
   isOnboarding: false
};

const getters = {
   user(state){
      return state.user
   },

   admin(state){
      return state.admin
   },

   isOnboarding(state) {
      return state.isOnboarding
   }
}

const mutations = {
   user(state,data){
      state.user = data;
      state.token = data.token ? data.token : state.token;
   },

   admin(state,data){
      state.admin = data;
      state.token = data.token ? data.token : state.token;
   },

   logout(state) {
      state.token = null
      state.user = {}
      state.admin = {}
   },

   isOnboarding(state) {
      state.isOnboarding = true
   }, 

   endOnboaring(state) {
      state.isOnboarding = false
   }
}

const actions = {

   async request_otp({ payload }, { email, phone }) {
      payload = { email, phone }
      const res = await PR.request_otp(payload)

      return res
   },

   async verify_otp({ payload }, { email, phone, otp }) {
      payload =  { email, phone, otp }
      const res = await PR.verify_otp(payload)

      return res
   },
   
   async register({ commit, dispatch }, { first_name, last_name, email, phone, password, password_confirmation, otp, platform }) {
      let payload = {first_name, last_name, email, phone, password, password_confirmation, otp, platform }

      const res = await PR.register(payload)
      commit('user', res)
      dispatch('getUserData')

      return res
   },

   async login({ commit, dispatch }, { email, password }) {
      let payload = { email, password }
      const res = await PR.login(payload)
      commit('user', res)
      if(res.message != 'proceed onboarding') {
         dispatch('getUserData')
      } else {
         commit('isOnboarding')
      }
      
      return res
   },

   async getUserData({ commit }) {
      const res = await PR.getUserData()
      commit('user',res.data);

      return res;
   },

   async getOnboardingUserData() {
      const res = await PR.getOnboardingUserData()

      return res
   },

   async admin_login({ commit, dispatch }, { email, password }) {
      let payload = { email, password }
      const res = await PR.admin_login(payload)

      commit('admin', res)
      dispatch('getAdminData')

      return res
   },

   async getAdminData({ commit }) {
      const res = await PR.getAdminData()
      commit('admin',res.data);

      return res;
   },

   async logout({commit}){
      const res = await commit('logout');      
      await router.push('/auth/sign-in');
      window.localStorage.removeItem('escrowlock')
      window.localStorage.removeItem('_secure__ls__metadata')
      window.localStorage.removeItem('isOnboarding')
      return res;
   },

   async admin_logout({commit}){
      const res = await commit('logout');      
      await router.push({ name: 'AdminLogin' });

      return res;
   },

   async token_terminate({ dispatch }) {
      const res = await PR.token_terminate()

      dispatch('logout')

      return res
   },

   async admin_token_terminate({ dispatch }) {
      const res = await PR.admin_token_terminate()

      dispatch('admin_logout')

      return res
   },

   async update_information({ payload }, { first_name, last_name, email, phone }) {
      payload = { first_name, last_name, email, phone }

      const res =  await PR.update_information(payload)
      return res
   },

   async update_security({ payload }, { old_password, new_password, password_confirmation }) {
     payload = { old_password, new_password, password_confirmation }

     const res =  await PR.update_security(payload)
     return res
   },

   async finishOnboarding({ commit, dispatch }, { first_name, last_name, email, phone, password, password_confirmation, platform } ) {
      let payload = { first_name, last_name, email, phone, password, password_confirmation, platform }
      const res = await PR.finishOnboarding(payload)
      commit('user', res)
      commit('endOnboaring')
      dispatch('getUserData');
      return res
   },

   async get_countries() {
      const res = await PR.get_countries()

      return res
   },

   async get_states({ payload }, { ciso }) {
      payload = { ciso }
      const res = await PR.get_states(payload)

      return res
   },

   async get_cities({ payload }, { country, state }) {
      payload = { country, state }
      const res = await PR.get_cities(payload)

      return res
   },

   async setup_profile(
         { payload }, 
         { first_name, middle_name, last_name, username, phone, user_country, user_state, city, address, business_name, business_email, business_phone, business_address, business_bank_name, business_bank_account_number, business_bank_account_name, user_bank, account_number, account_name }
      ) {
         payload = { first_name, middle_name, last_name, username, phone, user_country, user_state, city, address, business_name, business_email, business_phone, business_address, business_bank_name, business_bank_account_number, business_bank_account_name, user_bank, account_number, account_name }
         const res = await PR.setup_profile(payload)

         return res
   }
}

export default {
   namespaced:true, state, getters, mutations, actions
}
