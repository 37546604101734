import Vue from 'vue'
import App from './App.vue'
import 'tw-elements'
import '@/assets/css/tailwind.css'
import '@/assets/css/onegeco.css'
import router from '@/router/index.js'
import store from '@/store/index.js'
import { MazPhoneNumberInput, MazDropzone, MazPicker, MazLoader } from 'maz-ui'
import '@/plugins/filter'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { messages } from 'vee-validate/dist/locale/en.json'
import 'animate.css'
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"
import VueCookies from 'vue-cookies'
import OtpInput from "@bachdgvn/vue-otp-input"
import Vidle from 'v-idle'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css';
import paystack from 'vue-paystack';

// Prevent duplicates of the same type of toast
const filterBeforeCreate = (toast, toasts) => {
  if (toasts.filter(t => t.type === toast.type).length !== 0) {
      return false
  }
  return toast
}

const swalOtptions = {
  customClass: {
    
  }
}

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule],
    message: messages[rule]
  });
});

Vue.config.productionTip = false

Vue.use(MazPhoneNumberInput)
Vue.use(MazDropzone)
Vue.use(MazPicker)
Vue.use(MazLoader)
Vue.use(Toast, { filterBeforeCreate })
Vue.use(VueCookies)
Vue.use(Vidle)
Vue.use(VueSweetalert2, swalOtptions)

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component("v-otp-input", OtpInput);
Vue.component('Paystack', paystack);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
