import {RepositoryFactory as Repo} from "@/repository/RepositoryFactory"
import router from '../../router'

const PR = Repo.get('request')

const state = {
   admin: {},
   token: null
};

const getters = {
   
}

const mutations = {
   admin(state,data){
      state.admin = data;
      state.token = data.token ? data.token : state.token;
   },

   logout(state) {
      state.token = null
      state.admin = {}
   }
}

const actions = {

   async admin_login({ commit, dispatch }, { email, password }) {
      let payload = { email, password }
      const res = await PR.admin_login(payload)

      commit('admin', res)
      dispatch('getAdminData')
      return res
   },

   async getAdminData({ commit }) {
      const res = await PR.getAdminData()
      commit('admin',res.data);

      return res;
   },

   async logout({commit}){
      const res = await commit('logout');      
      await router.push('/auth/sign-in');

      return res;
   },

   async token_terminate({ dispatch }) {
      const res = await PR.token_terminate()

      dispatch('logout')

      return res
   },

   async update_security({ payload }, { old_password, new_password, password_confirmation }) {
     payload = { old_password, new_password, password_confirmation }

     const res =  await PR.update_security(payload)
     return res
   }
}

export default {
   namespaced:true, state, getters, mutations, actions
}
