<template>
  <div>
    <component :is="layouts">
      <router-view />
    </component>
    <!--v-idle
        style="display: none;"
        @idle="onidle"
        :loop="true"
        :wait="5"
        :duration="300" /-->
  </div>
</template>

<script>

export default {
  name: 'App',

  components: {
    
  },

  computed: {
    layouts() {
      const view = this.$route.meta.layout || 'div'
      return view
    }
  },

  methods: {
    onidle() {
      if (this.$store.state.auth.token) {
        this.$toast.error('Session timeout!')
        this.$store.dispatch('auth/token_terminate')
      }
    }
  }
}
</script>

<style>

</style>
