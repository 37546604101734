import Vue from 'vue'
import moment from 'moment'

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
});

Vue.filter('ucwords', function (value) {
  if (!value) return ''
  value = value.toString()
  /*value = value.split(" ");
  for (var i = 0; i < value.length; i++) {
    value[i] = value[i].charAt(0).toUpperCase() + value[i].slice(1)
  }
  value = value.join(" ")*/
  return value.toUpperCase()
})

Vue.filter('formatPrice', function (value) {
  let val = (value / 1).toFixed(2).replace(',', '.')
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
});

Vue.filter('formatNumber', function (value) {
  // var n = parseInt(value.replace(/\D/g, ''), 10)
  // return n.toLocaleString()
  return value
});

Vue.filter('formatDate', function (value) {
  return moment(value).format('MMMM Do, YYYY')
});

Vue.filter('tx_date', function (value) {
  return moment(value).format('ddd LLL')
});

Vue.filter('txDate', function (value) {
  return moment(value).format('YYYY-MM-DD')
});

Vue.filter('date_time', function (value) {
  return moment(value).format('YYYY-MM-DD, H:mm')
});

Vue.filter('date', function (value) {
  return moment(value).format('DD/MM/YYYY')
});

Vue.filter("dashboardDateFormat", function(value) {
  if (value) {
    return moment(String(value)).format("Do MMM [,] YYYY");
  }
  return 'Null'
});

Vue.filter("floatNairaFormat", function(value) {
  if (value) {
    const formatter = new Intl.NumberFormat(
        'en-NG', { style: 'currency', currency: 'NGN' }
    );
    return formatter.format(parseFloat(value).toFixed(2));
  }
  return '₦0.00'
});

Vue.filter("nairaFormat", function(value) {
  if (value) {
    const formatter = new Intl.NumberFormat(
        'en-NG', { style: 'currency', currency: 'NGN' }
    );
    return formatter.format(value);
  }
  return '₦0.00'
});

Vue.filter('stringToNumber', function (value) {
  return value
});

Vue.filter('reverse', function (value) {
  // slice to make a copy of array, then reverse the copy
  return value.slice().reverse()
});

Vue.filter('wholenumber', function (str) {
  let value = str || '0'
  return value.substring(0, value.indexOf('.'))
})

Vue.filter('decimalplace', function (str) {
  let value = str || '0'
  return value.split('.')[1]
})
