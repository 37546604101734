import Vue from 'vue'
import Vuex from 'vuex'
import auth from "./modules/auth"
import transactions from './modules/transactions'
import wallets from './modules/wallets'
import dashboard from './modules/dashboard'
import admin from './modules/admin'
import adminAuth from './modules/admin-auth'
import SecureLS from 'secure-ls'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

let ls = new SecureLS({
  encodingType: 'aes',
  isCompression: false,
  encryptionSecret: 'escrowlock'
})

export default new Vuex.Store({
  modules: {
    auth,
    transactions,
    dashboard,
    admin,
    wallets,
    adminAuth
  },
  plugins: [
    createPersistedState({
      key: "escrowlock",
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ],
})