import {RepositoryFactory as Repo} from "@/repository/RepositoryFactory"

const PR = Repo.get('request')

const getters = {

}

const state = {

}

const mutations =  {
   
}

const actions = {
   async top_up_ngn(payload, { amount, charge, gateway, status }) {
      payload =  { amount, charge, gateway, status }
      const res = await PR.top_up_ngn(payload)
      
      return res
   }
}

export default {
   namespaced: true,
   getters,
   actions, 
   state,
   mutations
}