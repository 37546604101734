import auth from '../../router/middleware/auth'

const protectedRoutes = [
   
   /***************************************** USER ***********************************************/
   {
      path: '/account/user',
      name: 'UserLoading',
      component: () => import('./user/index.vue'),
      meta: {
         middleware: [ auth ],
         // layout: () => import('@/layouts/user'),
         title: "welcome",
      }
   },

   {
      path: '/account/user/onboarding',
      name: 'UserOnboarding',
      component: () => import('./user/onboarding.vue'),
      meta: {
         // middleware: [ auth ],
         // layout: () => import('@/layouts/user'),
         title: "Onboarding",
      }
   },

   {
      path: '/account/user/dashboard',
      name: 'Dashboard',
      component: () => import('./user/dashboard.vue'),
      meta: {
         middleware: [ auth ],
         layout: () => import('@/layouts/user'),
         title: "Account Dashboard",
      }
   },

   {
      path: '/account/user/transactions',
      name: 'Transactions',
      component: () => import('./user/transaction/transactions.vue'),
      meta: {
         middleware: [ auth ],
         layout: () => import('@/layouts/user'),
         title: "Transactions",
      },
   },

   {
      path: '/account/user/transaction/:id',
      name: 'SelectedTransaction',
      component: () => import('./user/transaction/selected-transaction.vue'),
      meta: {
         middleware: [ auth ],
         layout: () => import('@/layouts/user'),
         title: "Transactions",
      }
   },

   {
      path: '/account/user/create/transaction',
      name: 'CreateTransaction',
      component: () => import('./user/transaction/create-transaction.vue'),
      meta: {
         middleware: [ auth ],
         layout: () => import('@/layouts/user'),
         title: "Create New Transaction",
      }
   },

   {
      path: '/account/user/wallets',
      name: 'Wallet',
      component: () => import('./user/wallets/index.vue'),
      meta: {
         middleware: [ auth ],
         layout: () => import('@/layouts/user'),
         title: "Wallets",
      }
   },

   {
      path: '/account/user/profile/',
      component: () => import('./user/profile/index.vue'),
      children: [
         {
            path: '',
            name: 'Profile',
            component: () => import('./user/profile/profile.vue'),
            meta: {
               middleware: [ auth ],
               layout: () => import('@/layouts/user'),
               title: "Account Profile",
            }
         },

         {
            path: 'banking',
            name: 'Banking',
            component: () => import('./user/profile/banking.vue'),
            meta: {
               middleware: [ auth ],
               layout: () => import('@/layouts/user'),
               title: "Banking Profile",
            }
         },
      
         {
            path: 'contacts',
            name: 'Contacts',
            component: () => import('./user/profile/contacts.vue'),
            meta: {
               middleware: [ auth ],
               layout: () => import('@/layouts/user'),
               title: "Contacts",
            }
         }
      ],
      meta: {
         middleware: [ auth ],
         layout: () => import('@/layouts/user'),
         title: "Profile",
      }
   },

   {
      path: '/account/user/security/',
      component: () => import('./user/security/index.vue'),
      children: [
         {
            path: '',
            name: 'Security',
            component: () => import('./user/security/change-password.vue'),
            meta: {
               middleware: [ auth ],
               layout: () => import('@/layouts/user'),
               title: "Change Password",
            }
         },

         {
            path: 'change-password',
            name: 'ChangePassword',
            component: () => import('./user/security/change-password.vue'),
            meta: {
               middleware: [ auth ],
               layout: () => import('@/layouts/user'),
               title: "Change Password",
            }
         },

         {
            path: '2-fa-settings',
            name: 'TwoFactor',
            component: () => import('./user/security/two-fa-settings.vue'),
            meta: {
               middleware: [ auth ],
               layout: () => import('@/layouts/user'),
               title: "2FA Settings",
            }
         },

         {
            path: 'anti-phishing',
            name: 'AntiPhishing',
            component: () => import('./user/security/anti-phishing.vue'),
            meta: {
               middleware: [ auth ],
               layout: () => import('@/layouts/user'),
               title: "Anti Phishing",
            }
         },

         {
            path: 'security-questions',
            name: 'SecurityQuestions',
            component: () => import('./user/security/security-questions.vue'),
            meta: {
               middleware: [ auth ],
               layout: () => import('@/layouts/user'),
               title: "Security Questions",
            }
         },

         {
            path: 'finger-print-authorization',
            name: 'FingerPrintAuth',
            component: () => import('./user/security/finger-print-authorization.vue'),
            meta: {
               middleware: [ auth ],
               layout: () => import('@/layouts/user'),
               title: "Finger Print Authorization",
            }
         },
      ],
      meta: {
         middleware: [ auth ],
         layout: () => import('@/layouts/user'),
         title: "Account Security",
      }
   },

   {
      path: '/account/user/affiliate-program',
      name: 'Affiliate',
      component: () => import('./user/affiliate.vue'),
      meta: {
         middleware: [ auth ],
         layout: () => import('@/layouts/user'),
         title: "Affiliate Program",
      }
   },

   {
      path: '/account/user/support',
      name: 'Support',
      component: () => import('./user/support.vue'),
      meta: {
         middleware: [ auth ],
         layout: () => import('@/layouts/user'),
         title: "Support Ticket",
      }
   },

   /***************************************** ADMIN ***********************************************/

   {
      path: '/account/admin/:id/:token/secure/',
      component: () => import('./admin/index.vue'),
      children: [
         {
            path: '',
            component: () => import('./admin/dashboard.vue'),
            meta: {
               middleware: [ auth ],
               layout: () => import('@/layouts/admin'),
               title: "Admin Dashboard",
            }
         },

         {
            path: 'dashboard',
            name: "AdminDashboard",
            component: () => import('./admin/dashboard.vue'),
            meta: {
               middleware: [ auth ],
               layout: () => import('@/layouts/admin'),
               title: "Admin Dashboard",
            }
         },

         {
            path: 'escrow-transactions',
            name: "EscrowTransactions",
            component: () => import('./admin/escrow-transactions.vue'),
            meta: {
               middleware: [ auth ],
               layout: () => import('@/layouts/admin'),
               title: "Escrow Transactions",
            }
         },

         {
            path: 'payment-transactions',
            name: "PaymentTransactions",
            component: () => import('./admin/payment-transactions.vue'),
            meta: {
               middleware: [ auth ],
               layout: () => import('@/layouts/admin'),
               title: "Payment Transactions",
            }
         },

         {
            path: 'user-accounts',
            name: "UserAccounts",
            component: () => import('./admin/user-accounts.vue'),
            meta: {
               middleware: [ auth ],
               layout: () => import('@/layouts/admin'),
               title: "User Accounts",
            }
         },

         {
            path: 'staff-accounts',
            name: "StaffAccounts",
            component: () => import('./admin/staff-accounts.vue'),
            meta: {
               middleware: [ auth ],
               layout: () => import('@/layouts/admin'),
               title: "Staff Accounts",
            }
         },

         {
            path: 'admin-accounts',
            name: "AdminAccounts",
            component: () => import('./admin/admin-accounts.vue'),
            meta: {
               middleware: [ auth ],
               layout: () => import('@/layouts/admin'),
               title: "Admin Accounts",
            }
         },

         {
            path: 'support-tickets',
            name: "SupportTicket",
            component: () => import('./admin/support.vue'),
            meta: {
               middleware: [ auth ],
               layout: () => import('@/layouts/admin'),
               title: "Support Tickets",
            }
         },

         {
            path: 'subscribers',
            name: "Subscribers",
            component: () => import('./admin/subscribers.vue'),
            meta: {
               middleware: [ auth ],
               layout: () => import('@/layouts/admin'),
               title: "Subscribers",
            }
         },

         {
            path: 'basic-settings',
            name: "BasicSettings",
            component: () => import('./admin/basic-settings.vue'),
            meta: {
               middleware: [ auth ],
               layout: () => import('@/layouts/admin'),
               title: "Basic Settings",
            }
         },

         {
            path: 'email-settings',
            name: "EmailSettings",
            component: () => import('./admin/email-settings.vue'),
            meta: {
               middleware: [ auth ],
               layout: () => import('@/layouts/admin'),
               title: "EmailSettings",
            }
         },

         {
            path: 'sms-settings',
            name: "SmsSettings",
            component: () => import('./admin/sms-settings.vue'),
            meta: {
               middleware: [ auth ],
               layout: () => import('@/layouts/admin'),
               title: "Sms Settings",
            }
         },

         {
            path: 'push-notifications',
            name: "PushNotifications",
            component: () => import('./admin/notification-settings.vue'),
            meta: {
               middleware: [ auth ],
               layout: () => import('@/layouts/admin'),
               title: "PushNotifications",
            }
         }
      ],
      meta: {
         middleware: [ auth ],
         layout: () => import('@/layouts/public-admin'),
         title: "Admin Login",
      }
   },
   /***************************************** STAFF ***********************************************/
]

export default protectedRoutes