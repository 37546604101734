import {RepositoryFactory as Repo} from "@/repository/RepositoryFactory"

const PR = Repo.get('request')

const getters = {

}

const state = {

}

const mutations =  {

}

const actions = {
   async checkisregistered({ payload }, { email }) {
      payload = { email }
      const res = await PR.checkisregistered(payload)

      return res
   },

   async create_transaction(
      { payload }, 
      { role, currency, category, service_name, seller_phone, seller_name, seller_email, price, duration, description, milestone, buyer_phone, buyer_name, buyer_email }) {
      
      payload = { role, currency, category, service_name, seller_phone, seller_name, seller_email, price, duration, description, milestone, buyer_phone, buyer_name, buyer_email }
      const res = await PR.create_transaction(payload)

      return res
   },

   async get_transactions({ payload }, { query }) {
      payload = { query }
      const res = await PR.get_transactions(payload)

      return res
   },

   async get_payments({ payload }, { query }) {
      payload =  { query }
      const res =  await PR.get_payments(payload)

      return res
   },

   async get_transaction_info({ payload }, { transaction_id }) {
      payload = { transaction_id }
      const res = await PR.get_transaction_info(payload)

      return res
   },

   async get_banks({ payload }, { country }) {
      payload = { country }
      const res = await PR.get_banks(payload)

      return res
   },

   async validate_account_number({ payload }, { account_number, bank_code }) {
      payload = { account_number, bank_code }
      const res = await PR.validate_account_number(payload) 

      return res
   },

   async decline_transaction({ payload }, { transaction_id }) {
      payload =  { transaction_id }
      const res = await PR.decline_transaction(payload) 

      return res
   },

   async accept_transaction({ payload }, { transaction_id }) {
      payload =  { transaction_id }
      const res = await PR.accept_transaction(payload) 

      return res
   },

   async confirm_escrow({ payload }, { transaction_id, amount, charge, gateway, currency, status }) {
      payload = { transaction_id, amount, charge, gateway, currency, status }
      const res = await PR.confirm_escrow(payload)

      return res
   }
}

export default {
   namespaced: true,
   getters,
   actions, 
   state,
   mutations
}