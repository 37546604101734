import {RepositoryFactory as Repo} from "@/repository/RepositoryFactory"
const PR = Repo.get('request')

const getters = {}

const state = {}

const mutations = {}

const actions = {
   async check_route_status({ payload }, { id, token }) {
      payload  = { id, token }
      const res = await PR.check_route_status(payload)

      return res
   },

   async get_dashboard_summery() {
      const res = await PR.get_admin_dashboard_summery()

      return res
   }
}

export default {
   namespaced: true, getters, state, actions, mutations
}