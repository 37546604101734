import {RepositoryFactory as Repo} from "@/repository/RepositoryFactory"

const PR = Repo.get('request')

const getters = {

}

const state = {

}

const mutations = {

}

const actions = {
   async get_dashboard_summery() {
      const res = await PR.get_dashboard_summery()

      return res
   }
}

export default {
   namespaced: true, getters, state, mutations, actions
}