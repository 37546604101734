import Vue from 'vue'
import axios from "axios"
import store from "@/store/index.js"

axios.defaults.withCredentials = false;
axios.interceptors.response.use(response => response.data)
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (!error.response) {
        Vue.$toast.error('Network Error, Please check your network connection!')
    }
    if (error.response.status === 401 || error.response.data.message === 'Token is Expired') {
        store.dispatch('auth/logout')
    }
    if (error.response.status === 500) {
        Vue.$toast.error('Something went wrong!, Please try again')
    }
    return Promise.reject(error);
});

//Default setup for Authorization
axios.interceptors.request.use(function (config) {
    const token = store.state.auth.token;
    config.headers.Authorization =  `Bearer ${token}`;
    config.headers['dev-auth'] = process.env.VUE_APP_ENV_ACCESS_GRANT
    return config;
});

let url = process.env.VUE_APP_API_URL

export default {

    /******************* AUTHENTICATION *************************/
    request_otp(payload) {
        return axios.post(url + '/auth/send/otp', payload)
    },

    verify_otp(payload) {
        return axios.post(url + '/auth/verify/otp', payload)
    },

    register(payload) {
        return axios.post(url + '/auth/register', payload)
    },

    login(payload) {
        return axios.post(url + '/auth/login', payload)
    },

    token_terminate() {
        return axios.post(url + '/auth/logout')
    },

    getUserData() {
        return axios.get(url + '/user/info')
    },

    getOnboardingUserData() {
        return axios.get(url + '/onboarding/user/info')
    },

    finishOnboarding(payload) {
        return axios.put(url + '/onboarding/finish', payload)
    },

    setup_profile(payload) {
        return axios.post(url + '/user/profile/setup', payload)
    },

    /******************* DASHBOARD *************************/
    get_dashboard_summery() {
        return axios.get(url + '/user/dashboard/summery')
    },

    /******************* TRANSACTIONS *************************/
    checkisregistered(payload) {
        return axios.get(url + '/auth/check/isregistered', { params: { email: payload.email } })
    },

    create_transaction(payload) {
        return axios.post(url + '/user/create/transaction', payload)
    },

    get_transactions(payload) {
        return axios.get(url + '/user/transactions', { params: { query: payload.query } })
    },

    get_payments(payload) {
        return axios.get(url + '/user/transactions/payments', { params: {query: payload.query }})
    },

    get_transaction_info(payload) {
        return axios.get(url + '/user/transaction/' + payload.transaction_id )
    },

    get_banks(payload) {
        return axios.get(url + '/banks', { params: payload })
    },

    validate_account_number(payload) {
        return axios.get(url + '/bank/verify/account', { params: payload })
    },

    decline_transaction(payload) {
        return axios.post(url + '/user/transaction/invitation/decline', payload)
    },

    accept_transaction(payload) {
        return axios.post(url + '/user/transaction/invitation/accept', payload)
    },

    confirm_escrow(payload) {
        return axios.post(url + '/user/transaction/escrow/paid', payload)
    },

    /******************* WALLET *************************/
    top_up_ngn(payload) {
        return axios.post(url + '/user/wallet/ngn/top-up', payload)
    },

    /************************************** ADMIN *************************************/

    check_route_status(payload) {
        return axios.get(url + '/admin/check/' + payload.id + '/access/' + payload.token )
    },

    admin_login(payload) {
        return axios.post(url + '/auth/admin/login', payload)
    },

    getAdminData() {
        return axios.get(url + '/admin/info')
    },

    admin_token_terminate() {
        return axios.post(url + '/auth/logout')
    },

    get_admin_dashboard_summery() {
        return axios.get(url + '/admin/dashboard/overview')
    },

    //get_countries() {
      //  return axios.get('https://countriesnow.space/api/v0.1/countries/iso')
    //},

    get_countries() {
        return axios.get(url + '/countries')
    },

    get_states(payload) {
        return axios.get(url + '/' + payload.ciso + '/states')
    },

    /*get_states(payload) {
        return axios.post('https://countriesnow.space/api/v0.1/countries/states', payload)
    },*/

    get_cities(payload) {
        return axios.post('https://countriesnow.space/api/v0.1/countries/state/cities', payload)
    }
}