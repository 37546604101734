export default function auth ({ next, store }){
   
    if(!store.state.auth.token){
       return next('/auth/sign-in')
    }
    
    if(store.state.auth.isOnboarding) {
        store.commit('auth/logout')
        return next('/auth/sign-in')
    }

   return next()
}