import Vue from 'vue'
import VueRouter from 'vue-router'
import protectedRoutes from "@/pages/protected/index.js";
import publicRoutes from "@/pages/public/index.js"
import store  from "@/store/index.js";
import middlewarePipeline from "./kernel/middlewarePipeline.js";

Vue.use(VueRouter)

const routes = [
    ...protectedRoutes,
    ...publicRoutes
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
 },
})

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }
  const middleware = to.meta.middleware;

  const context = {
    to,
    from,
    next,
    store
  }
  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  })
});

const default_title = process.env.VUE_APP_NAME

router.afterEach((to) => {
   document.title = "Escrowlock | " + to.meta.title || default_title
});

export default router