import guest from '../../router/middleware/guest'

const publicRoutes = [
   /************************************ USER *****************************************/
   {
      path: '/',
      redirect: '/transaction/start',
   },
   {
      path: '/transaction/start',
      name: 'NewTransaction',
      component: () => import('./user/new-transaction.vue'),
      meta: {
         //middleware: [ guest ],
         layout: () => import('@/layouts/public'),
         title: "New Transaction",
      }
   },
   {
      path: '/auth/sign-in',
      name: 'Login',
      component: () => import('./user/auth/login.vue'),
      meta: {
         middleware: [ guest ],
         layout: () => import('@/layouts/public'),
         title: "Login",
      }
   },
   {
      path: '/auth/sign-up',
      name: 'Register',
      component: () => import('./user/auth/register.vue'),
      meta: {
         middleware: [ guest ],
         layout: () => import('@/layouts/public'),
         title: "Sign Up",
      }
   },
   
   /***************************************** ADMIN ***********************************************/
   {
      path: '/admin/:id/:token/secure/',
      component: () => import('./admin/index.vue'),
      children: [
         {
            path: '',
            component: () => import('./admin/auth/login.vue'),
            meta: {
               middleware: [ guest ],
               layout: () => import('@/layouts/public-admin'),
               title: "Welcome",
            }
         },

         {
            path: 'login',
            name: "AdminLogin",
            component: () => import('./admin/auth/login.vue'),
            meta: {
               middleware: [ guest ],
               layout: () => import('@/layouts/public-admin'),
               title: "Welcome",
            }
         }
      ],
      meta: {
         middleware: [ guest ],
         layout: () => import('@/layouts/public-admin'),
         title: "Welcome",
      }
   },

   /***************************************** STAFF ***********************************************/
   { 
      path: '*', 
      redirect: '/' }
]

export default publicRoutes